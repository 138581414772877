<template>
	<div class="page-index">
		<div class="carousel">
			<div class="wrapper">
				<div class="content">
					<div style="width: 980px; height: 642px; position: relative;">
						<div class="cover" :style="{ backgroundImage: `url(${currentBanner.cover})` }" v-if="!playing">
							<div class="btn-play" @click="onPlay"></div>
						</div>
						<ws-player ref="player"></ws-player>
					</div>
					<div class="mask"><a class="entry" href="javascript:;" @click="onEnter">进入直播间</a></div>
				</div>
				<div class="side">
					<div :class="['side-item', bannerIndex == index ? 'active' : '']" v-for="(banner, index) in banners"
						:key="index" @click="changeBanner(index)">
						<img :src="banner.cover" @error="onCoverError" />
						<div>{{ banner.title }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="wrapper">
			<transition name="fade">
				<div class="match" v-if="hot.length > 0">
					<div class="match-header">
						<img class="icon" src="../assets/img/icon-live.png" />
						<div class="title">正在热播</div>
						<span class="line"></span>
						<a href="/lives/1">查看更多</a>
					</div>
					<div class="match-body"><ws-match-item v-for="(item, index) in hot" :key="index"
							:item="item"></ws-match-item></div>
				</div>
			</transition>
			<transition name="fade">
				<div class="match" v-if="hot_anchor.length > 0">
					<div class="match-header">
						<img class="icon" src="../assets/img/icon-anchor.png" />
						<div class="title">热门主播</div>
						<span class="line"></span>
					</div>
					<div class="match-swiper">
						<div class="swiper">
							<swiper :options="swiperOption">
								<swiper-slide class="swiper-slide" v-for="item in hot_anchor" :key="item.uid">
									<a :href="`/detail/${item.uid}`" target="_blank" class="swiper-item">
										<div class="avatar"><img :src="item.face" @error="onAvatarError" /></div>
										<span class="name">{{ item.nickname }}</span>
									</a>
								</swiper-slide>
								<div class="swiper-button-prev" slot="button-prev"></div>
								<div class="swiper-button-next" slot="button-next"></div>
							</swiper>
						</div>
					</div>
				</div>
			</transition>
			<transition name="fade">
				<div class="match" v-if="football.length > 0">
					<div class="match-header">
						<img class="icon" src="../assets/img/icon-football.png" />
						<div class="title">足球直播</div>
						<span class="line"></span>
						<a href="/lives/2">查看更多</a>
					</div>
					<div class="match-body"><ws-match-item v-for="(item, index) in football" :key="index"
							:item="item"></ws-match-item></div>
				</div>
			</transition>
			<transition name="fade">
				<div class="match" v-if="basketball.length > 0">
					<div class="match-header">
						<img class="icon" src="../assets/img/icon-basketball.png" />
						<div class="title">篮球直播</div>
						<span class="line"></span>
						<a href="/lives/3">查看更多</a>
					</div>
					<div class="match-body"><ws-match-item v-for="(item, index) in basketball" :key="index"
							:item="item"></ws-match-item></div>
				</div>
			</transition>
			<transition name="fade">
				<div class="match" v-if="synthesis.length > 0">
					<div class="match-header">
						<img class="icon" src="../assets/img/icon-comprehensive.png" />
						<div class="title">综合直播</div>
						<span class="line"></span>
						<a href="/lives/99">查看更多</a>
					</div>
					<div class="match-body"><ws-match-item v-for="(item, index) in synthesis" :key="index"
							:item="item"></ws-match-item></div>
				</div>
			</transition>
			<ws-fixed-bottom></ws-fixed-bottom>
		</div>
	</div>
</template>

<script>
import wsPlayer from '@/components/wsPlayer.vue';
import wsMatchItem from '@/components/wsMatchItem.vue';
import wsFixedBottom from '@/components/wsFixedBottom.vue';
export default {
	name: 'Home',
	components: {
		wsPlayer,
		wsMatchItem,
		wsFixedBottom
	},
	data() {
		return {
			bannerIndex: -1,
			banners: [],
			hot: [],
			hot_anchor: [],
			basketball: [],
			football: [],
			synthesis: [],
			swiperOption: {
				slidesPerView: 8,
				prevButton: '.swiper-button-prev',
				nextButton: '.swiper-button-next'
			},
			playing: false
		};
	},
	computed: {
		currentBanner() {
			return this.banners.length > this.bannerIndex ? this.banners[this.bannerIndex] : {}
		}
	},
	mounted() {
		this.$bus.$on('refresh', this.getHomeData);
		this.$loading(30000);
		this.getHomeData();
	},
	beforeDestroy() {
		this.$bus.$off('refresh', this.getHomeData);
	},
	methods: {
		async getHomeData() {
			const response = await this.$api.get('/web/index');
			if (response && response.code == 200) {
				const { banner = [], hot = [], hot_anchor = [], football, basketball, synthesis } = response.data;
				this.banners = banner.map(this.makeMatchItem);
				this.hot = hot.map(this.makeMatchItem);
				this.hot_anchor = hot_anchor;
				this.football = football.map(this.makeMatchItem);
				this.basketball = basketball.map(this.makeMatchItem);
				this.synthesis = synthesis.map(this.makeMatchItem);
				if (banner.length) {
					this.changeBanner(0);
				}
				this.$nextTick(() => {
					this.$loadend();
				});
			}
		},
		changeBanner(index) {
			if (this.banners.length > index) {
				this.playing = false
				try {
					this.$refs.player.player.instance.dispose();
				} catch (e) { }
				const banner = this.banners[index];
				if (banner) {
					this.bannerIndex = index;
				}
			}
		},
		onPlay() {
			if (this.bannerIndex > -1) {
				this.playing = true
				this.$refs.player.initPlayer(this.currentBanner);
			}
		},
		onEnter() {
			if (this.banners.length > this.bannerIndex) {
				const banner = this.banners[this.bannerIndex];
				this.$router.push(banner.href);
			}
		}
	}
};
</script>
<style lang="less">
.content {
	.cover {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		background-color: #000000;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
	}
}

.btn-play {
	position: absolute;
	bottom: 120px;
	left: 30px;
	z-index: 2;
	width: 64px;
	height: 64px;
	background: url('https://g.alicdn.com/de/prismplayer/2.9.11/skins/default/img/bigplay.png') no-repeat center center;
	background-size: contain;
	cursor: pointer;
}
</style>

